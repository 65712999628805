<template>
  <div>
    <v-alert prominent type="info" text>
      <span v-html="$t('feedbacks.mandatoryProcessInfo')"></span>
      <!-- <span v-html="$t('feedbacks.systemUpdateInformation')"></span> -->
    </v-alert>

    <v-card flat class="segmented">
      <v-tabs v-model="tabIndex" grow :vertical="isVertical">
        <v-tab>{{ $t('directEmployees') }}</v-tab>
        <v-tab>{{ $t('indirectEmployees') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabIndex">
        <v-tab-item>
          <v-card-text>
            <feedbacks-table
              :feedbacks="feedbacks"
              :loading="directFeedbacksLoading"
              :statusQuery="statusQuery"
              :processTypeQuery="processTypeQuery"
              :keywordQuery="keyword"
              :context="'direct_reports'"
              searchable
              @process-created="loadFeedbacks"
            ></feedbacks-table>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <feedbacks-table
              :feedbacks="indirectFeedbacks"
              :loading="indirectFeedbacksLoading"
              :statusQuery="statusQuery"
              :processTypeQuery="processTypeQuery"
              :keywordQuery="keyword"
              :context="'indirect_reports'"
              searchable
            ></feedbacks-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import feedbacksTable from '@/components/feedbacks/feedbacks-table.vue';

export default {
  data() {
    return {
      feedbacks: [],
      indirectFeedbacks: [],
      directFeedbacksLoading: true,
      indirectFeedbacksLoading: true,
      tabIndex: null,
      statusQuery: null,
      processTypeQuery: null
    };
  },

  props: {
    status: {
      type: String,
      required: false,
      default: ''
    },

    type: {
      type: String,
      required: false,
      default: ''
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    indirect: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    isVertical() {
      const bpName = this.$vuetify.breakpoint.name;
      return bpName === 'xs' || bpName === 'sm';
    }
  },

  watch: {
    async tabIndex() {
      if (this.tabIndex === 1 && this.indirectFeedbacksLoading) {
        var requests = [this.listIndirectFeedbacks(), this.listIndirectPdps()];
        var indirectFeedbacks = await Promise.all(requests);
        this.indirectFeedbacks = indirectFeedbacks.flat();
        this.indirectFeedbacksLoading = false;
      }
    }
  },

  async mounted() {
    if (this.type && this.type.length > 0) {
      this.processTypeQuery = [parseInt(this.type)];
      if (this.status && this.status.length > 0) {
        const statuses = await this.mapStatus({
          processType: this.type,
          statusId: this.status
        });
        this.statusQuery = statuses.map((x) => ({
          processType: parseInt(this.type),
          statusId: x
        }));
      }
    }

    this.tabIndex = parseInt(this.indirect);
    this.$hub.$on('donor-processes-updated', this.loadFeedbacks);
    this.loadFeedbacks();
  },

  beforeDestroy() {
    this.$hub.$off('donor-processes-updated', this.loadFeedbacks);
  },

  methods: {
    ...mapActions({
      listFeedbacks: 'feedbacks/listFeedbacksAsDonor',
      listPdps: 'pdp/listAsSupervisor',
      listIndirectFeedbacks: 'feedbacks/listFeedbacksFromHierarchy',
      listIndirectPdps: 'pdp/listFromHierarchy',
      mapStatus: 'workflows/mapStatus'
    }),

    async loadFeedbacks() {
      this.directFeedbacksLoading = true;
      var requests = [this.listFeedbacks(), this.listPdps()];
      var feedbacks = await Promise.all(requests);
      this.feedbacks = feedbacks.flat();
      this.directFeedbacksLoading = false;
    }
  },

  components: {
    feedbacksTable
  }
};
</script>
